import React, { useContext } from "react";
import * as prismic from "@prismicio/client";
import { Box, Heading, Text } from "@chakra-ui/react";
import Link from "next/link";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import styles from "./HeroParallax.module.css";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";
/**
 * Props for `HeroParallax`.
 */
export type HeroParallaxProps = SliceComponentProps<Content.HeroParallaxSlice>;

/**
 * Component for "HeroParallax" Slices.
 */
const HeroParallax = ({ slice }: HeroParallaxProps): JSX.Element => {
  const backgroundImage = slice.primary.background_image?.url;
  const padding = prismic.asText(slice.primary.custom_padding);
  const fallbackImage = "/fallback-image-1.jpeg";

  const divStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${
      backgroundImage ? backgroundImage : fallbackImage
    })`,
    // padding: `${padding ? padding : "250px 0"}`,
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={[styles.parallaxItem, "parallaxImageHelper"].join(" ")}
          style={divStyle}
        >
          <div className="container">
            <FadeInWhenVisible>
              {slice.primary.title && (
                <Heading
                  textStyle="h1"
                  as="h1"
                  letterSpacing="1px"
                  color="brand.white"
                  textTransform="uppercase"
                >
                  {prismic.asText(slice.primary.title)}
                </Heading>
              )}
              {slice.primary.subtitle && (
                <Text textStyle="p" color="white" maxWidth="800px">
                  {prismic.asText(slice.primary.subtitle)}
                </Text>
              )}
              {slice.primary.show_cta && (
                <Box marginTop="40px">
                  <Link
                    className="button button--large"
                    href={prismic.asLink(slice.primary.cta_link)}
                  >
                    {prismic.asText(slice.primary.cta_text)}
                  </Link>
                </Box>
              )}
              {slice.primary.show_modal_cta && (
                <Box marginTop="40px">
                  <ScrollLink
                    className="button button--large"
                    to="calendlyBooking"
                    smooth
                    duration={500}
                  >
                    Arrange a Call
                  </ScrollLink>
                </Box>
              )}
            </FadeInWhenVisible>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroParallax;
